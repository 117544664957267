@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~nebular-icons/css/nebular-icons.css';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

//Fonts
@font-face {
  font-family: "Urbanist";
  src: url("/assets/fonts/Urbanist-Regular.ttf") format("opentype");
  font-weight: normal;
}

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

@mixin confirm-cancel-button {
  height: 2.5rem;
  font-size: 1rem;
}

@mixin center-action-cells {
  ::ng-deep th.mat-header-cell:last-of-type {
    text-align: center !important;
  }

  ::ng-deep td.mat-cell:last-of-type {
    text-align: center !important;
  }
}

:root {
  --green-agrotips: #11D067;
  --green-agrotips-hover: #02AD4F;
  --dark-blue-agrotips: #05243A;
  --chumbo-agrotips: #474747;
  --black-agrotips: #2e2e2e;
  --gray-agrotips: #868686;
  --gray2-agrotips: #F0F0F0;
  --light-gray-agrotips: #BFBFBF;
  --red-agrotips: #FF4040;
  --yellow-agrotips: #FFBA0A;

  --upload-modal-icon-size: 1.5rem;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

th {
  font-size: 1.125rem;
  font-weight: 400;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td {
  font-size: 1rem;
  font-weight: 400;
  color: var(--chumbo-agrotips);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.over-circles {
  position: relative;
  z-index: 20;
}

.top-section {
  margin: 2.5rem 0;
}

.search-input {
  background: #FFF !important;

  height: 3rem;

  font-size: 1rem;

  &::placeholder {
    font-family: 'Urbanist' !important;
    font-size: 1rem;
  }
}

.search-button {
  height: 2.5rem;
}

.mat-cell,
.mat-header-cell {
  padding: 1rem 2rem !important;
}

.button-primary {
  background: var(--green-agrotips) !important;

  border-radius: 100rem !important;
  border: none;

  text-transform: unset !important;
  font-size: 1rem !important;
  color: #FFF !important;
  font-weight: 700;
  font-family: 'Urbanist';
}

.button-secondary {
  background: #FFF !important;

  border: 3px solid var(--dark-blue-agrotips) !important;
  border-radius: 100rem !important;

  text-transform: unset !important;
  color: var(--dark-blue-agrotips) !important;

  font-weight: 700;
  font-family: 'Urbanist';
}

.button-primary:disabled {
  background: #dddddd !important;

  cursor: default !important;
}

.button-confirm {
  @include confirm-cancel-button();

  width: 14rem;

  margin-bottom: .5rem;

  background: var(--green-agrotips) !important;

  &:hover:not([disabled]) {
    background: var(--green-agrotips-hover) !important;
  }
}

.button-cancel {
  @include confirm-cancel-button();

  width: 9.125rem;

  margin-top: .5rem;
}

.button-cancel:hover {
  background-color: var(--dark-blue-agrotips) !important;
  color: #FFF !important;
}

.default-input {
  border: none !important;
  border-radius: .5rem !important;

  background: #f0f0f0 !important;

  color: var(--gray-agrotips) !important;
  font-weight: 400;

  &::placeholder {
    color: var(--gray-agrotips) !important;
  }
}

.create-update-nb-card {
  width: 35%;
  height: 38.6rem;

  margin: 0 !important;

  border: none;
  border-radius: 0 !important;
 
  @media screen and (max-width: 1025px) {
    height: 42.6rem;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
  }
}

.create-update-title {
  font-family: 'Urbanist';
  font-size: 1.5rem;
  font-weight: 700;

  margin-top: 2.375rem;
}

.magnifying-glass {
  color: var(--green-agrotips);
}

.edit-delete-icon {
  color: var(--dark-blue-agrotips);
}

.edit-icon:hover {
  color: var(--green-agrotips) !important;
}

.delete-icon:hover {
  color: var(--red-agrotips) !important;
}

.is-active {
  color: var(--green-agrotips);
}

.is-not-active {
  color: var(--red-agrotips);
}

.action-button {
  background: transparent !important;
  border: none !important;
}

.one-fourth-cell {
  width: 25% !important;
  max-width: 20vw !important;

  @media (max-width: 600px) {
    width: 20rem !important;
    max-width: 20rem !important;
  }
}

.one-third-cell {
  width: 34% !important;
  max-width: 30vw !important;

  @media (max-width: 600px) {
    width: 20rem !important;
    max-width: 20rem !important;
  }
}

.three-fourths-cell {
  width: 75% !important;
  max-width: 75vw !important;

  @media (max-width: 600px) {
    width: 20rem !important;
    max-width: 20rem !important;
  }
}

.lexical {
  &--strikethrough {
    text-decoration: line-through;
  }

  &--underline {
    text-decoration: underline;
  }

  &--underline-strikethrough {
    text-decoration: line-through underline;
  }
}

@media only screen and (min-width: 1680px) {
  html,
  body {
    font-size: 20px;
  }

  mat-paginator {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 2560px) {
  html,
  body {
    font-size: 30px;
  }

  mat-paginator {
    margin-top: .5rem;
    font-size: .9rem;
  }

  ::ng-deep mat-paginator .mat-icon-button {
    margin: 0 .8rem;
  }

  ::ng-deep mat-paginator .mat-paginator-icon {
    width: 1.6rem;
  }
}

@media only screen and (min-width: 3860px) {
  html,
  body {
    font-size: 45px;
  }

  mat-paginator {
    font-size: .9rem;
  }

  ::ng-deep .mat-paginator-range-label {
    padding-top: .7rem;
  }
}
